@import "./fonts.scss";
@import "./prizes.scss";
@import "./prizeCards.scss";
@import "./prizeRules.scss";
@import "./form.scss";

:root {
  --dark-color: #04071d;
  --light-color: #ffffff;
  --darkshow-color: #00083f;
  --lightshow-color: #00c1f3;
  --height: 100%;
}

body {
  margin: 0;
}

html {
  margin: 0;
  padding: 0;
  font-family: Helvetica;
  font-size: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--dark-color);
  color: var(--light-color);

  @media only screen and (min-width: 600px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.flex {
  display: flex;

  &--center {
    align-items: center;
  }

  &--middle {
    justify-content: center;
  }

  &--column {
    flex-flow: column;
  }

  &--row {
    flex-flow: row;
  }
}
