.PrizeRules {
  margin: 0;
  width: 100vw;
  background-color: var(--dark-color);
  position: absolute;

  &__head {
    background-color: var(--darkshow-color);
    width: 100%;

    &_logo {
      margin: 1rem auto 1rem 1rem;
      max-height: 1rem;
    }

    &_close {
      position: absolute;
      max-width: 1rem;
      right: 1rem;
      top: 1rem;
      padding: 0.1rem;
      border: 2px solid var(--lightshow-color);
    }
  }

  &__body {
    padding: 0 1.5rem;

    &_title {
      @extend .font-bell-heavy;
      position: relative;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.2;
      margin: 2rem auto 0.8rem;
      text-transform: uppercase;
      letter-spacing: 5px;
    }

    &_subtitle {
      position: relative;
      text-align: center;
      font-size: 1rem;
      line-height: 1.2;
      width: 75vw;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: var(--lightshow-color);
    }

    &_content {
      position: relative;
      font-size: 1.2rem;
      color: var(--light-color);

      &_title {
        font-size: 1rem;
        text-align: left;
        padding: 1rem 0 0.5rem;
        text-transform: uppercase;
        letter-spacing: 2.5px;
      }

      &_item {
        text-align: justify;
        font-size: 0.8rem;
        line-height: 1.2;
      }

      &_end {
        text-align: justify;
        font-size: 0.8rem;
        line-height: 1.2;
        padding: 0.5rem 0;
      }
    }
  }
}
