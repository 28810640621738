.PrizesPage {
  height: 100vh;
  width: 100vw;

  &__head {
    background-color: var(--darkshow-color);
    width: 100%;

    &_logo {
      margin: 1rem auto 1rem 1rem;
      max-height: 1rem;
    }
  }
  &__body {
    text-align: center;
    padding: 0 1.5rem;

    &_title {
      @extend .font-bell-heavy;
      position: relative;
      text-align: center;
      font-size: 2rem;
      line-height: 2.2rem;
      margin: 2rem auto 0.8rem;
      text-transform: uppercase;
      letter-spacing: 5px;
    }

    &_content {
      position: relative;
      text-align: center;
      font-size: 1rem;
      line-height: 1.2rem;
      text-transform: uppercase;
      color: var(--lightshow-color);
    }
  }
  &__rules {
    background-color: var(--secondary-color);
    width: 100%;
    bottom: 0;
    padding: 1rem 0 0 1rem;
    font-size: 0.8rem;
  }
  &__footer {
    background-color: var(--secondary-color);
    height: 4rem;
    width: 100%;
    bottom: 0;
    padding: 0.3rem 0 2rem 1rem;
    font-size: 0.8rem;
  }
}
