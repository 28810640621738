.PrizesPage {
  ._field {
    width: 65vw;
    height: 2.5rem;
    margin: 0.5rem auto;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
  }

  &__loading {
    @extend ._field;
    letter-spacing: 3px;
    padding: 0.7rem 0;
    border: 1px var(--lightshow-color) solid;
  }

  &__finished {
    @extend ._field;
    letter-spacing: 3px;
    padding: 0.7rem 0;
    background-color: var(--lightshow-color);
    color: var(--darkshow-color);
  }

  &__form {
    @extend .flex, .flex--center, .flex--column;

    &-input {
      @extend ._field;
      letter-spacing: 2px;
      background-color: var(--light-color);
      color: var(--darkshow-color);
    }

    &-button {
      @extend ._field;
      letter-spacing: 3px;
      background-color: var(--lightshow-color);
      color: var(--darkshow-color);
      border: none;
      cursor: pointer;
    }
  }
}
