.PrizeCards {
  margin: 1rem 0;

  &__card {
    border: 2px solid var(--lightshow-color);
    background-color: var(--darkshow-color);
    margin: 0.5rem 0;
    padding: 2rem 2rem;
    min-height: 385px;

    &_img {
      position: relative;
      padding: 1rem;

      &_Crave {
        width: 60vw;
      }

      &_Tiff {
        max-width: 40vw;
      }

      &_Bell {
        max-width: 70vw;
      }
    }

    &_title {
      @extend .font-bell-heavy;
      position: relative;
      text-align: center;
      font-size: 1.5rem;
      line-height: 2rem;
      width: 70vw;
      margin: 1rem 0;
      letter-spacing: 5px;
      text-transform: uppercase;
    }

    &_subtitle {
      position: relative;
      text-align: center;
      font-size: 0.8rem;
      line-height: 1rem;
      margin-bottom: 1.5rem;
      letter-spacing: 2px;
    }

    &_content {
      position: relative;
      text-align: center;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 1rem;
      color: var(--light-color);
    }
  }
}
